/** @jsx jsx */
import { jsx, useColorMode, useThemeUI } from 'theme-ui';
import { Helmet } from 'react-helmet';

import Page from '../components/Page';
import { H1, H2, H3, H4, H5, P } from '../components/Text';
import Fonts from '../components/Fonts';
// @ts-ignore
import Spritemap from '../../assets/logo-spritemap.svg';
import theme from '../gatsby-plugin-theme-ui';

import '../../styles/reset.css';
import '../../styles/base.css';
import { Button } from 'rebass';

const modes = ['default', 'dark', 'lightGreen'];

const BodyContent: React.FC<React.HTMLProps<HTMLDivElement>> = props => (
  <div
    sx={{
      width: '100%',
      bg: 'primary',
      py: ['16px', '24px', '32px'],
    }}
    {...props}
  />
);

const Section: React.FC<React.HTMLProps<HTMLDivElement>> = props => (
  <section
    sx={{ px: [3, 2, 1], width: '100%', maxWidth: '1050px', margin: '0 auto' }}
    {...props}
  />
);

const IndexPage = () => {
  const [mode, setMode] = useColorMode();

  const { theme } = useThemeUI();

  return (
    <Page>
      <Spritemap />
      <Fonts />
      <Helmet>
        {/*<script src="https://js.tito.io/v1" async></script>
        <link rel="stylesheet" type="text/css" href='https://css.tito.io/v1.1' />*/}
        <meta name="theme-color" content={theme.colors.red} />
        <link rel="shortcut icon" href={'/favicon.png'} type="image/x-icon" />
        <title>
          Interaction 21 • 31 January to 5 February 2021 • Montréal, Canada
        </title>
      </Helmet>
      <BodyContent
        sx={{
          height: '100vh',
          width: '100%',
          flexDirection: 'column',
          display: 'flex',
        }}
      >
        <Section>
          <H1>Heading 1</H1>
          <br />
          <H2>Heading 2</H2>
          <br />
          <H3>Heading 3</H3>
          <br />
          <H4>Heading 4</H4>
          <br />
          <H5>Heading 5</H5>
          <br />
          <P>This is paragraph text that will usually be a little longer.</P>
          <br />
          <Button variant="primary" sx={{ mb: 2 }}>
            This is a button
          </Button>
          <br />
          <Button variant="secondary">This is a button</Button>
        </Section>
      </BodyContent>
    </Page>
  );
};

export default IndexPage;
